import { useDispatch, useSelector } from "react-redux";
import {
  setConnect,
  setImportUiid,
  setImportInfo,
  setProcessing,
} from "../../redux/slices/importSlice";
import WsConnector from "../../utils/WsConnector";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import { useState, useEffect } from "react";

const ImportWsConnector = () => {
  const {
    isWSConnected,
    data,
    error,
    channels,
    cashedData,
    channelName,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const dispatch = useDispatch();
  const connect = useSelector((state) => state?.importSlice?.connect);
  const uuid = useSelector((state) => state?.importSlice?.importUuid);
  const importInfo = useSelector((state) => state?.importSlice?.importInfo);
  const shouldIgnore = useSelector(
    (state) => state?.importSlice?.blockWsConnector
  );
  const [nextStep, setStep] = useState(false);

  useEffect(() => {
    if (shouldIgnore) return;
    if (Object.keys(importInfo)?.length > 0) {
      dispatch(setConnect(false));
      dispatch(setImportUiid(null));
      if (Object.keys(channels).length > 0) {
        dispatch(actions.cleanData("update"));
        dispatch(actions.leaveChannel("general_info"));
      }
      dispatch(setProcessing(false));
      setStep(false);
    }
  }, [importInfo]);

  useEffect(() => {
    if (shouldIgnore) return;
    if (connect && uuid) {
      dispatch(actions.connect());
    }
  }, [connect, uuid]);

  useEffect(() => {
    if (shouldIgnore) return;
    if (isWSConnected && uuid) {
      dispatch(
        actions.joinChannel({
          name: "general_info",
          params: { uuid: uuid },
        })
      );
      setStep(true);
    }
  }, [isWSConnected, uuid]);

  useEffect(() => {
    if (shouldIgnore) return;
    if (Object.keys(channels).length > 0) {
      dispatch(
        actions.subscribeChannel({
          channelName: "general_info",
          eventName: "update",
        })
      );
    }
  }, [channels]);

  useEffect(() => {
    if (shouldIgnore) return;
    if (data?.["update"]?.length > 0 && isWSConnected) {
      debugger;
      dispatch(setImportInfo(data?.["update"]));
    }
  }, [data?.["update"]]);

  useEffect(() => {
    if (shouldIgnore) return;
    if (cashedData?.length > 0 && isWSConnected) {
      debugger;
      dispatch(setImportInfo({ [channelName]: { ...cashedData } }));
    }
  }, [cashedData]);
};
export default ImportWsConnector;
