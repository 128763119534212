import { store } from "../../redux/store";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import SearchIcon from "@mui/icons-material/Search";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import BalaceDatePicker from "./BalaceDatePicker";
import { Form } from "@rjsf/mui";
import {
  AccessTime,
  AccountCircle,
  Code,
  Description,
  Water,
  Settings,
} from "@mui/icons-material";
import EqualizeGraph from "./EqualizeGraph";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setBalancType } from "../../redux/slices/balanceSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BalanceGraph from "./BalanceGraph";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { setBalanceTrashId } from "../../redux/slices/balanceSlice";
import IwdDataGrid from "../IwdDataGrid";
import ModalDialog from "../../components/meters/ModalDialog";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import BalanceConsumGraph from "./BalanceConsumGraph";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import DeleteButton from "../../utils/DeleteButton";
import {
  IconButton,
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";

import { StyledDataGrid } from "../StyledDataGrid";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import theme from "../../theme";
const BalanceShow = () => {
  const [calendarMode, setCalendarMode] = useState("day");
  const [deleteBalance, { isSuccess: deleteSuccess }] =
    store.useDeleteBalanceMutation();
  const [balanceMode, setBalanceMode] = useState("day");
  const [trashModal, setTrashModal] = useState(false);
  const [fakeVal, setFakeVal] = useState("day");
  const [firstRender, setfirstRender] = useState(true);
  const [infoModal, setInfoModal] = useState(false);
  const [balanceAccordion, setBalanceAccordion] = useState(false);
  const [consumAccordion, setConsumAccordion] = useState(false);
  const [equalizeAccordion, setEqualizeAccordion] = useState(false);
  const [getBalanceValues] = store.useLazyGetBalanceValuesQuery();
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const balanceTrashId = useSelector((state) => state?.balanceSlice?.trashId);
  const parentmeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => item?.attributes?.serial_number);

  const impulsiveMeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item.children)
    ?.flat()
    ?.map((item) => item.children)
    ?.flat()
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => item?.attributes?.serial_number?.includes("-"));

  const allMeters = parentmeters?.length >= 0 &&
    impulsiveMeters.length >= 0 && [...parentmeters, ...impulsiveMeters];
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const balance = useSelector((state) => state?.balanceSlice?.balance);
  const balanceValues = useSelector(
    (state) => state?.balanceSlice?.balanceValues
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { balanceId } = useParams();
  const { id } = useParams();
  const [getBalanceShow] = store.useLazyGetBalanceShowQuery();
  const inMeters =
    allMeters?.length > 0 &&
    allMeters?.filter((item) =>
      balance?.meta?.in_meters?.includes(item?.attributes?.identifier)
    );
  const outMeters =
    allMeters?.length > 0 &&
    allMeters?.filter((item) =>
      balance?.meta?.out_meters?.includes(item?.attributes?.identifier)
    );
  const schema = {
    type: "object",
    properties: {
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        mode: calendarMode,
        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },
    },
  };
  useEffect(() => {
    if (balanceTrashId) {
      deleteBalance(balanceTrashId);
      dispatch(setBalanceTrashId(null));
    }
  }, [balanceTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      setTrashModal(false);
      navigate(-1);
    }
  }, [deleteSuccess]);
  const fields = {
    datepicker: BalaceDatePicker,
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: <SearchIcon />,
    },

    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  useEffect(() => {}, [outMeters, inMeters]);
  useEffect(() => {
    getBalanceShow(balanceId);
  }, [balanceId]);
  useEffect(() => {
    if (balance) {
      dispatch(setBalancType(balance?.balance_type));
    }
  }, [balance]);
  useEffect(() => {
    if (balanceValues?.length > 0) {
      setBalanceAccordion(true);
      setEqualizeAccordion(true);
      setConsumAccordion(true);
    } else {
      setBalanceAccordion(false);
      setEqualizeAccordion(false);
      setConsumAccordion(false);
    }
  }, [balanceValues?.length]);
  const columns = [
    {
      field: "subblock",
      headerName: t("subcondominum"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    /* {
      field: "room",
      headerName: t("room"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    }, */
    {
      field: "serial",
      headerName: t("serial_number"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("type"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <VisibilityIcon
              sx={{ fill: theme.palette.navIcon }}
              onClick={() => navigate(`/meters/${params?.value}`)}
            />
          </Box>
        );
      },
    },
  ];
  const inMeterRows =
    inMeters.length > 0 &&
    inMeters?.map((item, i) => ({
      id: i,
      subblock: item?.attributes?.sub_condominium_name,
      apartment: item?.attributes?.apartment_name,
      /*     room: item?.attributes?.room, */
      serial: item?.attributes?.serial_number,
      type: item?.attributes?.meter_type,
      actions: item?.attributes?.identifier,
    }));
  const outMeterRows =
    outMeters.length > 0 &&
    outMeters?.map((item, i) => ({
      id: i,
      subblock: item?.attributes?.sub_condominium_name,
      apartment: item?.attributes?.apartment_name,
      /*     room: item?.attributes?.room, */
      serial: item?.attributes?.serial_number,
      type: item?.attributes?.meter_type,
      actions: item?.attributes?.identifier,
    }));

  const onSubmitClick = () => {
    const params = {
      from: formData?.insertion_date?.from,
      to: formData?.insertion_date?.to,
      value_type: balanceMode,
      no_pagination: true,
    };
    getBalanceValues({
      id: balanceId,
      params: params,
    });
  };
  useEffect(() => {
    setfirstRender(true);
  }, [calendarMode]);
  useEffect(() => {
    if (formData?.insertion_date?.from && firstRender) {
      onSubmitClick();
      setfirstRender(false);
    }
  }, [formData?.insertion_date?.from]);

  useEffect(() => {
    if (!condominiumShow) {
      getCondominiumShow(id);
    }
  }, []);
  useEffect(() => {
    if (calendarMode == "month") {
      setFormData({});
    }
  }, [calendarMode]);

  useEffect(() => {
    if (balance && condominiumShow) {
      dispatch(
        setBreadCrumbs({
          id: condominiumShow?.name,
          balanceId: balance?.code,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [balance, condominiumShow]);

  const unitSelector = () => {
    switch (balance?.balance_type) {
      case "heating":
      case "cooling":
        return `kWh`;
      case "water":
        return "mc";
    }
  };
  useEffect(() => {}, [balance]);
  const balanceModeChange = (e) => {
    setBalanceMode(e?.target?.value);
  };
  const fakeValChange = (e) => {
    setFakeVal(e?.target?.value);
  };
  return (
    <>
      {condominiumShow && (
        <>
          <DeleteConfirmModal
            trashModal={trashModal}
            setTrashModal={setTrashModal}
            text={t("balance_delete_text")}
            title={t("balance_delete_title")}
            deleteFunc={() => setBalanceTrashId(balanceId)}
          />
          <ModalDialog open={infoModal} close={() => setInfoModal(false)}>
            <Box width={600}>
              <Typography align="center" variant="subtitle1" fontWeight="bold">
                {t("balance_form_intro")}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {t("balance_form_step1")}
                {
                  <IconButton>
                    <CalendarMonthIcon
                      sx={{ fill: theme.palette.success.main }}
                    />
                  </IconButton>
                }
                {t("balance_form_step2")}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {t("balance_form_step3")}
                {
                  <IconButton>
                    <CalendarViewMonthIcon
                      sx={{ fill: theme.palette.success.main }}
                    />
                  </IconButton>
                }
                , {t("balance_form_step4")}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {t("balance_form_step5")}
                {
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("time_interval")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={fakeVal}
                      label={t("time_interval")}
                      onChange={fakeValChange}
                    >
                      <MenuItem value={"day"}>{t("day")}</MenuItem>
                      <MenuItem value={"week"}>{t("week")}</MenuItem>
                      <MenuItem value={"month"}>{t("month")}</MenuItem>
                    </Select>
                  </FormControl>
                }
              </Typography>
              <Typography sx={{ mt: 1 }}>{t("balance_form_step6")}</Typography>
            </Box>
          </ModalDialog>
          {balance && condominiumShow && (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h4">{`${t("balance")}: ${
                  balance?.code
                } - ${balance?.name ?? " "}`}</Typography>
                <Box display={"flex"}>
                  <IwdAuthWrapper
                    children={
                      <Box
                        sx={{ mt: 2, mr: 2 }}
                        display={"flex"}
                        width={"100%"}
                        justifyContent={"flex-end"}
                      >
                        <Button
                          onClick={() => navigate("edit")}
                          variant={window.BUTTON_TYPE}
                        >
                          {t("update_balance")}
                        </Button>
                      </Box>
                    }
                    section={"upd_balance_btn"}
                  />
                  <IwdAuthWrapper
                    section={"upd_balance_btn"}
                    children={
                      <DeleteButton
                        name={t("delete")}
                        styles={{ height: 40, mt: 2 }}
                        onClick={() => setTrashModal(true)}
                      />
                      /* <Button
                        onClick={() => setTrashModal(true)}
                        sx={{
                          height: 40,
                          mt: 2,
                          color: theme.palette.error.main,
                         
                        }}
                      >
                        {t("delete")}
                      </Button> */
                    }
                  />
                </Box>
              </Box>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Grid container>
                    {balance?.name && (
                      <Grid xs={1}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                        >
                          {t("name")}:
                        </Typography>
                        <Typography>{balance?.name}</Typography>
                      </Grid>
                    )}
                    <Grid xs={1}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("code")}:
                      </Typography>
                      <Typography>{balance?.code}</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("balance_type")}:
                      </Typography>
                      <Typography>{t(balance?.balance_type)}</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("limit")}:
                      </Typography>
                      <Typography>{balance?.limit}</Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("inserted_at")}:
                      </Typography>
                      <Typography>
                        {t("dt", { val: new Date(balance?.inserted_at) })}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("updated_at")}:
                      </Typography>
                      <Typography>
                        {t("dt", { val: new Date(balance?.updated_at) })}
                      </Typography>
                    </Grid>
                    <Grid xs={4} sx={{ mt: 2 }}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("condominium")}:
                      </Typography>
                      <Typography>{condominiumShow?.name}</Typography>
                    </Grid>
                    {balance?.balance_type == "water_heating" && (
                      <Grid xs={3} sx={{ mt: 2 }}>
                        <Typography>{`${t("delta_t")}: ${
                          balance?.coefficients?.delta_t
                        }`}</Typography>
                        <Typography>{`${t("conversion_value")}: ${
                          balance?.coefficients?.conversion_value
                        }`}</Typography>
                      </Grid>
                    )}
                    <Grid xs={3} sx={{ mt: 2 }}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("description")}:
                      </Typography>
                      <Typography>{balance?.description ?? "--"}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t("meters")}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <Typography textAlign={"center"}>
                        {t("in_meters")}
                      </Typography>
                      <Box sx={{ height: 400, width: "100%", mt: 3 }}>
                        <IwdDataGrid
                          rows={inMeterRows ?? []}
                          columns={columns}
                          perPage={inMeterRows?.length}
                          count={inMeterRows?.length}
                          page={1}
                          paginationMode={"client"}
                          sort={false}
                          height={400}
                          tableName={"balance_in_meters"}
                        />
                        {/*  <StyledDataGrid
                          disableColumnMenu
                          rows={inMeterRows ?? []}
                          columns={columns}
                          disableSelectionOnClick
                          getRowHeight={() => "auto"}
                        /> */}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography textAlign={"center"}>
                        {t("out_meters")}
                      </Typography>
                      <Box sx={{ height: 400, width: "100%", mt: 3 }}>
                        <IwdDataGrid
                          rows={outMeterRows ?? []}
                          columns={columns}
                          perPage={outMeterRows?.length}
                          count={outMeterRows?.length}
                          page={1}
                          paginationMode={"client"}
                          sort={false}
                          height={400}
                          tableName={"balance_out_meters"}
                        />
                        {/* <StyledDataGrid
                          disableColumnMenu
                          rows={outMeterRows ?? []}
                          columns={columns}
                          disableSelectionOnClick
                          getRowHeight={() => "auto"}
                        /> */}
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box sx={{ width: 160 }}>
                        <Typography sx={{ mb: 2 }}>
                          {t("graphs")}{" "}
                          <IconButton onClick={() => setInfoModal(true)}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {t("time_interval")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={balanceMode}
                            label={t("time_interval")}
                            onChange={balanceModeChange}
                          >
                            <MenuItem value={"day"}>{t("day")}</MenuItem>
                            <MenuItem value={"week"}>{t("week")}</MenuItem>
                            <MenuItem value={"month"}>{t("month")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <Box display={"flex"}>
                          <Tooltip title={t("day_mode")}>
                            <IconButton onClick={() => setCalendarMode("day")}>
                              <CalendarMonthIcon
                                sx={{
                                  color:
                                    calendarMode == "day"
                                      ? theme.palette.success.main
                                      : "#737373",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("month_mode")}>
                            <IconButton
                              onClick={() => setCalendarMode("month")}
                            >
                              <CalendarViewMonthIcon
                                sx={{
                                  color:
                                    calendarMode == "month"
                                      ? theme.palette.success.main
                                      : "#737373",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Form
                        schema={schema}
                        uiSchema={uiSchema}
                        fields={fields}
                        formData={formData}
                        showErrorList={false}
                        onChange={({ formData: newFormData }) =>
                          setFormData(newFormData)
                        }
                        onSubmit={(values) => {
                          onSubmitClick(values.formData);
                        }}
                        validator={validator}
                      />
                    </Box>
                    <Accordion
                      onClick={() => setEqualizeAccordion(!equalizeAccordion)}
                      sx={{ mt: 2 }}
                      expanded={equalizeAccordion}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {balanceValues?.length > 0
                          ? t("equalization_percentage")
                          : `${t("equalization_percentage")}: ${t(
                              "no_available_data"
                            )}`}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ width: "90rem" }}>
                          <EqualizeGraph unit={"%"} />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ mt: 2 }}
                      expanded={balanceAccordion}
                      onClick={() => setBalanceAccordion(!balanceAccordion)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {balanceValues?.length > 0
                          ? t("balance")
                          : `${t("balance")}: ${t("no_available_data")}`}
                      </AccordionSummary>
                      <AccordionDetails>
                        <BalanceGraph unit={unitSelector()} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ mt: 2 }}
                      expanded={consumAccordion}
                      onClick={() => setConsumAccordion(!consumAccordion)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {balanceValues?.length > 0
                          ? t("consumptions")
                          : `${t("consumptions")}: ${t("no_available_data")}`}
                      </AccordionSummary>
                      <AccordionDetails>
                        <BalanceConsumGraph unit={unitSelector()} />
                      </AccordionDetails>
                    </Accordion>
                  </>
                </CardContent>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BalanceShow;
