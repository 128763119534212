import * as React from "react";
import Box from "@mui/material/Box";
import { store } from "../../../redux/store";
import Chip from "@mui/material/Chip";
import { StyledDataGrid } from "../../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IwdAuthWrapper } from "../../../utils/IwdAuthWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import IwdDataGrid from "../../../pages/IwdDataGrid";
import { Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  setMeterTrashId,
  setUpdateAfterDelete,
} from "../../../redux/slices/meterSlice";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import { useState, useEffect } from "react";
import { nameTransform } from "../../../utils/utilsFunctions";
import { useSelector, useDispatch } from "react-redux";
import DeleteConfirmModal from "../../../utils/DeleteConfirmModal";
const Table = ({
  data,
  response,
  count,
  perPage,
  page,
  setPage,
  hideAccordion,
  loading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const meterTrashId = useSelector((state) => state?.meterSlice?.trashId);
  const [deleteMeter, { isSuccess: deleteSuccess, error: deleteError }] =
    store.useRemoveMeterMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [trashId, setTrashId] = useState(null);
  const [modal, setModal] = useState(false);
  const [twinId, setTwinId] = useState(null);

  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);
  const onTrashClick = (id, twinId) => {
    setTrashId(id);
    setTwinId(twinId);
    setModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3.2,
      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      flex: 2.3,
      headerAlign: "left",
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "tags",
      headerName: t("tags"),
      type: "string",
      sortable: false,

      flex: 5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {params?.value?.map((item) => (
              <Chip
                key={item}
                style={{
                  backgroundColor: theme.palette.chip,
                  color: theme.palette.error.contrastText,
                  margin: "2px",
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "condominium_name",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      flex: 2.2,
      headerAlign: "left",
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),

      sortable: false,
      flex: 2.2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("inserted_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Link
              to={`/meters/${params.value?.id}`}
              onClick={() => dispatch(setMeterTrashId(null))}
            >
              <VisibilityIcon sx={{ mt: 1, fill: theme.palette.navIcon }} />
            </Link>
            <IwdAuthWrapper
              children={
                <IconButton
                  onClick={() => {
                    debugger;
                    onTrashClick(params?.value?.id, params?.value?.twinId);
                  }}
                >
                  <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                </IconButton>
              }
              section={"delete_meter"}
            />
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class,
    /* ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--" */ network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial_number
      ? item?.attributes?.fields?.serial_number
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    pod: item?.attributes?.fields?.pod ?? "--",
    diameter: item?.attributes?.fields?.diameter,
    tags: item?.attributes?.fields?.tags,
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.meta_data?.last_seen_at
      ? t("dt", { val: new Date(item?.attributes?.meta_data?.last_seen_at) })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",

    actions: {
      id: item.id,
      twinId: item?.attributes?.digital_twin_id,
    },
  }));
  useEffect(() => {
    if (meterTrashId) {
      deleteMeter(meterTrashId);
    }
  }, [meterTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      dispatch(setMeterTrashId(null));
      setTwinId(null);
      dispatch(setUpdateAfterDelete(true));
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (deleteError) {
      dispatch(setMeterTrashId(null));
      setTwinId(null);
    }
  }, [null]);
  return (
    <Box sx={{ height: "40vh", mb: 5 }}>
      <DeleteConfirmModal
        trashModal={modal}
        setTrashModal={setModal}
        text={t("meter_delete_text")}
        title={t("meter_delete_title")}
        deleteFunc={() => setMeterTrashId(trashId)}
      />
      <Box sx={{ mb: 3 }}>
        <IwdDataGrid
          columns={columns}
          perPage={perPage}
          setPage={setPage}
          rows={tableRows ? [...tableRows] : []}
          page={page}
          count={count ?? response?.data?.meta?.count}
          paginationMode={"server"}
          sort={false}
          height={545}
          tableName={"meters"}
        />
      </Box>
    </Box>
  );
};

export default Table;
