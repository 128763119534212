import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import IconButton from "@mui/material/IconButton";
import { Box, Typography, Button } from "@mui/material";
import DeleteButton from "../../utils/DeleteButton";
import { store } from "../../redux/store";
import { setUserTrashId } from "../../redux/slices/meterSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "../../theme";

import IwdDataGrid from "../../pages/IwdDataGrid";
import AssocMeterToUeser from "./AssocMeterToUeser";
import UserWidget from "../users/UserWidget";
import { actions } from "../../redux/slices/authSlice";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const AssoUserToApartment = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.userSlice?.users);
  const id = useSelector(
    (state) => state?.condominiumSlice?.apartamentIdentifier
  );
  const userTrashId = useSelector((state) => state?.meterSlice?.userTrashId);
  const associatedUsers = useSelector(
    (state) => state?.meterSlice?.associatedUsers
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const mappedUsers = users?.map((user) => ({
    label: `${user?.attributes?.email} (${user?.attributes?.first_name} ${user?.attributes?.last_name})`,
    value: user?.attributes?.id,
  }));
  const [getUsers, { isSuccess }] = store.useLazyGetUsersQuery();
  const [getAssociatedUser] = store.useLazyGetListOfAssociatedUserQuery();
  const [assocMeter, { isSuccess: assocSuccess }] =
    store.useAssocMeterToUserMutation();
  const [disasocMeter, { isSuccess: disasocSuccess }] =
    store.useDisasocMeterFromUserMutation();

  const [formData, setFormData] = useState({});
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const schema = {
    title: "New user",
    type: "object",

    properties: {
      user_id: {
        $id: "2",
        type: "string",
        title: t("user"),
        data: [...mappedUsers],
        /*   default: paramsList.zip_code, */
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("associate_user"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    user_id: {
      "ui:field": "user",
    },
  };
  const fields = {
    user: AssocMeterToUeser,
  };

  useEffect(() => {
    getAssociatedUser({
      id: id,
      params: {
        page: page,
        per_page: perPage,
        realtion_type: "end_user",
      },
    });
    getUsers({
      role: "end_user",
    });
  }, [id]);

  useEffect(() => {
    if (userTrashId) {
      disasocMeter({ deviceId: id, userId: userTrashId });
    }
  }, [userTrashId]);

  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("user_succ_associated")));
      getAssociatedUser({
        id: id,
        params: {
          page: page,
          per_page: perPage,
          realtion_type: "end_user",
        },
      });
    }
  }, [assocSuccess]);

  useEffect(() => {
    if (disasocSuccess) {
      dispatch(setMessage(t("user_succ_dissociated")));
      dispatch(setUserTrashId(null));
      getAssociatedUser({
        id: id,
        params: {
          page: page,
          per_page: perPage,
          realtion_type: "end_user",
        },
      });
    }
  }, [disasocSuccess]);

  useEffect(() => {
    console.log("formDatameaso", formData);
  }, [formData]);

  const onAssocClick = () => {
    const attributes = {
      realtion_type: "end_user",
      user_id: formData?.user_id,
    };
    assocMeter({
      id: id,
      attributes: attributes,
    });
  };
  const onTrashClick = (id) => {
    setTrashId(id);
    setDeleteModal(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "first_name",
      headerName: t("first_name"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "last_name",
      headerName: t("last_name"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "username",
      headerName: t("username"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "email",
      headerName: t("email"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <IwdAuthWrapper
              children={
                <IconButton onClick={() => onTrashClick(params?.value)}>
                  <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                </IconButton>
              }
              section={"disasoc_user_from_meter"}
            />
          </Box>
        );
      },
    },
  ];

  const rows = associatedUsers?.map((item, i) => ({
    id: item?.id ?? i,
    first_name: item?.attributes?.first_name,
    last_name: item?.attributes?.last_name,
    username: item?.attributes?.username,
    email: item?.attributes?.email,
    actions: item?.id,
  }));

  return (
    <>
      {isSuccess && (
        <>
          <DeleteConfirmModal
            trashModal={deleteModal}
            setTrashModal={setDeleteModal}
            text={t("user_disasoc_text")}
            title={t("user_disasoc_title")}
            deleteFunc={() => setUserTrashId(trashId)}
          />
          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={fields}
            formData={formData}
            showErrorList={false}
            onChange={({ formData: newFormData }) => setFormData(newFormData)}
            /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
            validator={validator}
            children={true}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              disabled={!formData?.user_id}
              onClick={onAssocClick}
              variant={window?.BUTTON_TYPE}
            >
              {t("assoc_user")}
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <IwdDataGrid
              rows={rows}
              columns={columns}
              perPage={perPage}
              setPage={setPage}
              page={page}
              count={rows?.length}
              sort={false}
              height={600}
              tableName={"meters_with_users"}
              paginationMode={"server"}
            />
          </Box>
        </>
      )}
    </>
  );
};
export default AssoUserToApartment;
