import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { store } from "../../redux/store";

import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import Box from "@mui/material/Box";
import Point from "../form/formComponents/Point";

import DigitalTwinImage from "../form/formComponents/DigitalTwinImage";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import { setDigitalTwin } from "../../redux/slices/digitalTwinSlice";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import MeterTypeWidget from "./widgets/MeterTypeWidget";
import ImpulsiveInput from "../form/formComponents/ImpulsiveInput";
import HeatMeterForm from "../form/formComponents/HeatMeterForm";
import { useNavigate } from "react-router-dom";
import TagsWidget from "./widgets/TagsWidget";
import TextareWidget from "./widgets/TextareaWidget";
import TimeZoneWidget from "./widgets/TimeZoneWidget";
import { setSelectedMeter } from "../../redux/slices/meterSlice";
import ReverseWidget from "./widgets/ReverseWidget";
import NetworkAdapterWidget from "./widgets/NetworkAdapterWidget";
import SelectWidget from "./widgets/SelectWidget";

const fields = {
  Point,
  DigitalTwinImage,
  SecurityWidget,
  ImpulsiveInput,
  MeterTypeWidget,
  TagsWidget,
  TimeZoneWidget,
  TextareWidget,
  ReverseWidget,
  NetworkAdapterWidget,
  SelectWidget,
};

const generateFormConfig = (config, t, meterType) => {
  const schema = {
    title: "Dynamic Form",
    type: "object",
    properties: {},
    required: [],
    errorMessage: {
      properties: {},
    },
  };

  const uiSchema = {
    "ui:order": [],
  };

  const fields = {};

  Object.entries(config)
    .filter(([, value]) => value.create)
    .sort(([, a], [, b]) => a.order - b.order)
    .forEach(([key, value]) => {
      schema.properties[key] = {
        title: t(key),
        type: value.type,
        ...(value.selectValues && { selectValues: value.selectValues }),
        ...(value.default && { default: value.default }),
        ...(value.readOnly && { readOnly: value.readOnly }),
        ...(value.properties && { properties: value.properties }),
      };

      if (value.required) {
        schema.required.push(key);
      }

      if (value.errorMessage) {
        schema.errorMessage.properties[key] = value.errorMessage;
      }

      uiSchema[key] = {
        "ui:field": value.widget,
        "ui:options": {
          size: value.sizeCreate || 6,
        },
      };

      if (value.widget === "MeterTypeWidget") {
        uiSchema[key]["ui:options"] = {
          ...uiSchema[key]["ui:options"],
          meterType: meterType,
        };
      }

      if (value.widget) {
        fields[key] = value.widget;
      }

      uiSchema["ui:order"].push(key);
    });

  return { schema, uiSchema, fields };
};

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        const size = props.uiSchema[element.name]?.["ui:options"]?.size || 6;

        return (
          <Grid key={element.id} item xs={size}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const DinamicMeterCreate = ({
  setMeterData,
  closeModal,
  meterData,
  singleMode = false,
}) => {
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [meterType, setMeterType] = useState(null);
  const [createMeter, { data, isLoading, error, isSuccess: createSuccess }] =
    store.useCreateMeterMutation();
  const [updateMeter, { data: updData }] = store.useUpdateMeterMutation();
  const [meterId, setMeterId] = useState(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(false);
  const [twinId, setTwinId] = useState(null);
  const [identifireType, setIdentifireType] = useState(null);
  const [getTwins] = store.useLazyGetDigitalTwinsQuery();
  const [heatValidation, setHeatValidation] = useState("waiting");
  useEffect(() => {
    getTwins();
  }, []);

  const security = IwdFormAuthWrapper({ section: "security" });
  const digitalTwins = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwins
  );

  const mappedTwins = digitalTwins?.map((dt) => ({
    label: dt?.attributes?.name,
    value: dt?.id,
  }));

  const selectedDigitalTwin = digitalTwins?.filter(
    (item) => item?.id == twinId
  );
  useEffect(() => {
    if (data && !singleMode) {
      setMeterData(data?.data);
      closeModal();
    }
  }, [data]);
  useEffect(() => {
    if (data && singleMode) {
      navigate(`/meters/${data?.data?.id}`);
    }
  }, [data]);
  useEffect(() => {
    if (updData && !singleMode) {
      debugger;
      setMeterData(updData?.data);
      closeModal();
    }
  }, [updData]);
  useEffect(() => {
    if (selectedDigitalTwin?.length > 0 && twinId) {
      dispatch(setDigitalTwin(selectedDigitalTwin));
      dispatch(setSelectedMeter(selectedDigitalTwin));
      setMeterType(
        selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields?.type
      );
      setIdentifireType(
        selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields
          ?.identifier_type
      );
    }
  }, [selectedDigitalTwin, twinId, dispatch]);
  /*   useEffect(() => {
    if (meterId) {
      navigate(`/meters/${meterId}`);
    }
  }, [meterId]); */

  useEffect(() => {
    console.log("twinId", formData);
  }, [formData]);

  const currentConfig =
    twinId && (window.METER?.[twinId] || window.METER.default);
  const { schema, uiSchema } = currentConfig
    ? generateFormConfig(currentConfig, t, meterType)
    : { schema: null, uiSchema: null, fields: null };

  useEffect(() => {
    console.log("schema", meterType);
  }, [meterType]);
  const onSubmitClick = (formData) => {
    if (!meterData) {
      const attributes = {
        fields: {
          class:
            selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields?.class,
          ...formData,
          digital_twin_id: twinId?.toString(),
          security:
            security && formData?.security?.length > 0
              ? {
                  acl: [...formData?.security],
                  groups: ["/network_adapter_manager_router", "/codec_manager"],
                }
              : {
                  acl: [
                    {
                      name: "/admin",
                      scopes: ["Elixir.show", "Elixir.update"],
                    },
                  ],
                  groups: ["/admin"],
                },
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
        },
      };

      if (formData.meter_type === "RIPARTITORE") {
        setHeatValidation("validation");
      } else {
        createMeter({ attributes: attributes, twinId: twinId });
      }
    } else {
      const attributes = {
        fields: {
          class:
            selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields?.class,
          ...formData,
          digital_twin_id: twinId?.toString(),
          security:
            security && formData?.security?.length > 0
              ? {
                  acl: [...formData?.security],
                  groups: ["/network_adapter_manager_router", "/codec_manager"],
                }
              : {
                  acl: [
                    {
                      name: "/admin",
                      scopes: ["Elixir.show", "Elixir.update"],
                    },
                  ],
                  groups: ["/admin"],
                },
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
        },
      };

      if (formData.meter_type === "RIPARTITORE") {
        setHeatValidation("validation");
      } else {
        updateMeter({
          attributes: attributes,
          twinId: meterData?.attributes?.digital_twin_id,
          instanceId: meterData?.id,
        });
      }
    }
  };

  useEffect(() => {
    if (heatValidation === "validated" && !meterData) {
      const attributes = {
        fields: {
          class:
            selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields?.class,
          ...formData,
          digital_twin_id: twinId?.toString(),
          security:
            security && formData?.security?.length > 0
              ? {
                  acl: [...formData?.security],
                  groups: ["/network_adapter_manager_router", "/codec_manager"],
                }
              : {
                  acl: [
                    {
                      name: "/admin",
                      scopes: ["Elixir.show", "Elixir.update"],
                    },
                  ],
                  groups: ["/admin"],
                },
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
        },
      };
      updateMeter({
        attributes: attributes,
        twinId: meterData?.attributes?.digital_twin_id,
        instanceId: meterData?.id,
      });
    }
  }, [heatValidation]);
  useEffect(() => {
    if (heatValidation === "validated" && meterData) {
      const attributes = {
        fields: {
          class:
            selectedDigitalTwin?.[0]?.attributes?.digital_twin_fields?.class,
          ...formData,
          digital_twin_id: twinId?.toString(),
          security:
            security && formData?.security?.length > 0
              ? {
                  acl: [...formData?.security],
                  groups: ["/network_adapter_manager_router", "/codec_manager"],
                }
              : {
                  acl: [
                    {
                      name: "/admin",
                      scopes: ["Elixir.show", "Elixir.update"],
                    },
                  ],
                  groups: ["/admin"],
                },
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
        },
      };
      createMeter({ attributes: attributes, twinId: twinId });
    }
  }, [heatValidation, meterData]);
  const customValidate = (formData, errors) => {
    Object.entries(currentConfig).forEach(([key, value]) => {
      const fieldValue = formData[key];

      if (value.pattern && value.errorMessage) {
        if (fieldValue && !new RegExp(value.pattern).test(fieldValue)) {
          if (errors[key] && typeof errors[key].addError === "function") {
            errors[key].__errors = [];
            errors[key].addError(value.errorMessage);
          }
        }
      }
    });

    return errors;
  };

  const transformErrors = (errors) => {
    return errors.map((error) => {
      if (error.name === "required") {
        error.message = t("required_property");
      }
      return error;
    });
  };
  return (
    <Box>
      <Autocomplete
        sx={{ mb: 3, mt: 1 }}
        disablePortal
        id="combo-box-demo"
        options={mappedTwins ?? []}
        onChange={(event, newValue) => {
          setTwinId(newValue?.value);
        }}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Digital Twin" />}
      />

      {schema && (
        <Box>
          <Form
            schema={schema}
            uiSchema={{
              "ui:ObjectFieldTemplate": objectFieldTemplate,
              "ui:submitButtonOptions": {
                submitText: !meterData ? t("create") : t("update"),
                props: {
                  variant: window?.BUTTON_TYPE,
                  color: window?.theme?.buttonText,
                },
              },
              ...uiSchema,
            }}
            fields={fields}
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            customValidate={customValidate}
            transformErrors={transformErrors}
            noHtml5Validate
            validator={validator}
            showErrorList={false}
          />
          {formData.meter_type == "RIPARTITORE" && (
            <HeatMeterForm
              setFormData={setFormData}
              formData={formData}
              heatValidation={heatValidation}
              setHeatValidation={setHeatValidation}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DinamicMeterCreate;
